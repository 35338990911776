<template>
  <div>
    <vx-card class="hide-on-mobile" style="min-height: 50px;border-radius:8px;"></vx-card>
    <div class="mt-5 tab-wrapper" v-if="coreSkillsData.length">
      <vs-tabs class="activity-tab" v-model="activeTab">
        <!--        //-->
        <vs-tab v-for="(item,index) in coreSkillsData" :key="index" :label="item.name">
          <div>
            <CoreSkillTab
              :data="item"
              :core-skill-id="item._id"
              @changeRating="onChangeRating"
            ></CoreSkillTab>
          </div>
        </vs-tab>
      </vs-tabs>

    </div>
    <!--    <vx-card class="mb-base">-->
    <!--      <vs-row class="core-skill-header">-->
    <!--        <vs-col vs-type="flex" class="w-full">-->
    <!--          <div>-->
    <!--            <ul class="demo-alignment" v-if="coreSkillsData.length">-->
    <!--              <li v-for="(item,index) in coreSkillsData" v-bind:key="index" :class="(index === activeCoreSkillKey)  ? 'active' : ''">-->
    <!--                <vs-button type="border" @click="selectCoreSkill(item,index)">{{item.name}}</vs-button>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </vs-col>-->
    <!--      </vs-row>-->
    <!--    </vx-card>-->
    <!--    <vx-card class="mb-base">-->
    <!--      <h2 class="mb-0">Rating</h2>-->
    <!--        <vs-row class="core-skill-header">-->
    <!--        <vs-col vs-type="flex" class="w-full">-->
    <!--          <div>-->
    <!--            <ul class="demo-alignment" v-if="ratingArray.length">-->
    <!--              <li v-for="(item,index) in ratingArray" v-bind:key="index" :class="(index === activeRatingKey)  ? 'active' : ''">-->
    <!--                <vs-button type="border" @click="onChangeRating(item.value, index)">{{item.label}}</vs-button>-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </vs-col>-->
    <!--      </vs-row>-->
    <!--    </vx-card>-->

    <!--    <vx-card class="mb-base">-->
    <!--      <vs-input-->
    <!--        class="w-full search-input mb-5"-->
    <!--        v-model="searchQuery"-->
    <!--        placeholder="Search"-->
    <!--        icon-after="true"-->
    <!--        label-placeholder="Search"-->
    <!--        icon="icon icon-search"-->
    <!--        icon-pack="feather"-->
    <!--      />-->
    <!--    </vx-card>-->
    <!--    <vx-card class="mb-base skill-list-detail">-->
    <!--      <div class="mt-5">-->
    <!--        <h2 class="mb-0">Resources</h2>-->
    <!--        <template v-if="coreSkillDevelopments.length > 0">-->
    <!--          <vs-row v-if="coreSkillDevelopments.length > 0" :key="index"-->
    <!--                  v-for="(item,index) in coreSkillDevelopments">-->
    <!--            <vs-col vs-justify="center" vs-align="center" vs-w="9">-->
    <!--              <p class="h4"><a :href="item.document" target="_blank">{{item.title}}</a></p>-->
    <!--              <p>{{item.description}}</p>-->
    <!--            </vs-col>-->
    <!--            <vs-divider/>-->
    <!--          </vs-row>-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--          <vs-row>-->
    <!--            <vs-col vs-justify="center" vs-align="center" vs-w="9">-->
    <!--              <p>Sorry no data found.</p>-->
    <!--            </vs-col>-->
    <!--            <vs-divider/>-->
    <!--          </vs-row>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </vx-card>-->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions} from "vuex";
import CoreSkillTab from './CoreSkillTab';

export default {
  data() {
    return {
      rating: "1",
      coreSkills: {},
      coreSkillsData: [],
      activeTab: 0,

      coreSkillDevelopments: {},
      coreSkillDevelopmentID: null,

      currentResource: {},
      activeCoreSkillKey: 0,
      activeRating: 1,
      activeRatingKey: 0,

      coreSkillId: '',

      searchQuery: "",
      awaitingSearch: false,

      ratingArray: [
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5}
      ]
    }
  },
  watch: {
    searchQuery: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getCoreSkillDevelopments();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    ...mapActions("coreSkill", [
      "getCenterAdminCoreSkillDevelopment",
      "fetchCenterAdminCoreSkills",
      "logProfessionalDevelopment",
      "setRating"
    ]),
    async onChangeRating(val, key) {
      this.rating = val;
      this.activeRatingKey = key;
      await this.setRating(this.rating);
      this.getCoreSkillDevelopments();
    },
    getCoreSkillDevelopments() {
      let filter = {
        coreSkill: this.coreSkillId,
        rating: this.rating,
      };
      let info = {
        data: filter,
        search: this.searchQuery
      };
      this.$vs.loading()
      this.getCenterAdminCoreSkillDevelopment(info)
        .then((res) => {
          this.$vs.loading.close();
          this.coreSkillDevelopments = res.data.data.map((item => {
            return item.resources
          }));
        })
        .catch(err => {
          this.$vs.loading.close()
        })
    },
    async selectCoreSkill(val, key) {
      this.coreSkillId = await val._id;
      this.rating = 1;
      this.activeRatingKey = 0;
      this.activeCoreSkillKey = await key;
      this.getCoreSkillDevelopments();
    }
  },
  components: {
    vSelect,
    CoreSkillTab
  },
  created() {
    this.rating = this.$route.params.rating || 1;
    this.$vs.loading()
    this.fetchCenterAdminCoreSkills()
      .then((res) => {
        this.$vs.loading.close();
        this.coreSkillsData = res.data.data;
        this.coreSkillId = this.coreSkillsData[0]._id;
        this.activeCoreSkillKey = 0;
        this.getCoreSkillDevelopments();
      })
      .catch(err => {
        this.$vs.loading.close()
      });
  },

}
</script>

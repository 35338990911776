<template>
  <div>
    <vx-card class="mb-base">
      <h2 class="mb-0">Resources</h2>
      <vs-row class="mb-5">
        <vs-col class="md:w-1/2 w-full">
          <vs-input
            class="w-full search-input"
            v-model="searchQuery"
            placeholder="Search"
            icon-after="true"
            label-placeholder="Search"
            icon="icon icon-search"
            icon-pack="feather"
          />
        </vs-col>
        <vs-col class="md:w-1/2 w-full flex justify-end">
          <div>
            <vs-select
              placeholder="Rating: 1"
              vs-multiple
              autocomplete
              v-model="ratingFilter"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in ratingOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <!--  <v-select :options="ratingOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="ratingFilter"/>-->
        </vs-col>
      </vs-row>

<!--      <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
          <vs-button
            color="primary" type="filled"
            @click="documentSendPopup()">Professional development log
          </vs-button>
          </vs-col>
      </vs-row>-->

      <vs-row v-if="coreSkillDevelopments.length > 0" :key="index" v-for="(item,index) in coreSkillDevelopments" class="mt-10">
        <vs-col vs-justify="center" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
          <p class="h4">
            <a :href="item.link" v-if="item.link" target="_blank">{{ item.title }}</a>
            <a :href="item.document" v-else target="_blank">{{ item.title }}</a>
          </p>
          <p>{{ item.description }}</p>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-xs:justify="start" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
          <vs-button
            type="border"
            icon-pack="feather"
            v-if="isOnlyTeacher"
            icon="icon-plus"  @click="sendToTeacher(item._id)">Send to me</vs-button>
          <vs-button
            type="border"
            icon-pack="feather"
            icon="icon-plus" @click="documentDetailPage(item._id, rating)"
            v-else>Professional development log</vs-button>
        </vs-col>

<!--        <vs-col vs-type="flex" vs-justify="center" vs-xs:justify="start" vs-align="center" vs-lg="3" vs-sm="4"
                vs-xs="12">-->
          <!--          <vs-button-->
          <!--            type="border"-->
          <!--            icon-pack="feather"-->
          <!--            icon="icon-plus" @click="documentDetailPage(selectedFastCheck._id, rating)">Professional development log</vs-button>-->
<!--        </vs-col>-->
        <vs-divider/>
      </vs-row>
      <vs-row v-if="coreSkillDevelopments.length < 1" class="mt-10">
        <vs-col vs-justify="center" vs-align="center" vs-lg="9" vs-sm="8" vs-xs="12">
          No data found.
        </vs-col>
      </vs-row>
    </vx-card>



    <vs-popup class="holamundo add-children-popup" title :active.sync="teacherPopup">
      <form class="p-0">
        <h2 class="h1 mb-6">Send To teacher</h2>
        <vx-card class="m-0 my-8">
          <vs-row>
            <vs-col vs-w="12">
              <div v-if="rooms.length > 0">
                <vs-select
                  label="Select Room"
                  placeholder="Select Room"
                  autocomplete
                  v-model="currentRoom"
                  class="mr-6"
                  v-if="teacherRole !== true"
                >
                  <vs-select-item
                    :key="index"
                    :value="room._id"
                    :text="room.name"
                    v-for="(room,index) in rooms"
                    :clearable="false"
                  />
                </vs-select>
                <vs-select
                  label="Select Room"
                  :placeholder="this.roomName"
                  autocomplete
                  v-model="currentRoom"
                  class="mr-6"
                  disabled
                  v-else
                >
                  <vs-select-item
                    :key="index"
                    :value="room._id"
                    :text="room.name"
                    v-for="(room,index) in rooms"
                    :clearable="false"
                  />
                </vs-select>
                <span class="text-danger text-sm" v-show="roomError" >No Room selected.</span>
              </div>
              <p v-else>No rooms found</p>
            </vs-col>
          </vs-row>

          <vs-row class="mt-10">
            <vs-col vs-w="12">
              <div>
                <ul v-if="teachers.length > 0">
                  <li>
                    <div class="round--" style="display: flex; align-items: center">
                      <input
                        :id="'checkbox-a'"
                        type="checkbox"
                        @click="toggleAllTeachers($event)"
                      />
                      <span style="margin-left: 23px; font-size: 16px; line-height: 22px;">Select All Teachers</span>
                    </div>
                  </li>
                  <li v-for="(teacher, key) in teachers" :key="key">
                    <div class="round--" style="display: flex; align-items: center">
                      <input
                        :id="'checkbox-'+key"
                        name="selectedTeacher"
                        type="checkbox"
                        v-model="selectedTeachers"
                        :value="teacher._id"
                      />
                      <vs-avatar size="46px" :src="teacher.profileImage" style="margin-left: 22px;" />
                      <span
                        style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ teacher.fullName }}</span>
                    </div>
                  </li>
                  <span class="text-danger text-sm" v-show="teacherError" >No Teacher selected.</span>
                </ul>
                <p v-else>No teachers in this room.</p>
              </div>
            </vs-col>
          </vs-row>

<!--          <span class="text-danger text-sm" >errors</span>-->
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="cancelSendTeacher" color="primary" type="filled">Cancel</vs-button>
          <vs-button @click="sendToTeacher" color="primary" type="filled">Send</vs-button>
        </div>
      </form>
    </vs-popup>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      teacherPopup: false,
      selectedCoreSkill: '',
      fastCheckDevelopments: {},
      coreSkillDevelopments: [],
      ratingOptions: [
        {label: 'Rating: 1', value: 1},
        {label: 'Rating: 2', value: 2},
        {label: 'Rating: 3', value: 3},
        {label: 'Rating: 4', value: 4},
        {label: 'Rating: 5', value: 5},
      ],
      rating: 1,
      ratingFilter: {label: 'Rating: 1', value: 1},
      searchQuery: "",
      awaitingSearch: false,
      isOnlyTeacher: false,
      teacherRole: false,

      rooms:[],
      teachers:[],
      teachersIds:[],
      selectedTeachers:[],
      centerId:null,
      currentRoom:null,
      teacherError:null,
      roomError:null

    }
  },
  props: {

    data: {
      type: Object,
      default: () => {
      },
    },
    coreSkillId: {},
  },
  watch: {
    currentRoom(roomId){
      if(roomId){
        this.roomError = null;
      }
      const roomIndex = this.rooms.findIndex((u) => u._id == roomId)
      this.teachers = this.rooms[roomIndex].teacherList;
      this.teachersIds = this.rooms[roomIndex].teachers;
    },
    selectedTeachers(teacherIds){
      if(teacherIds.length > 0){
        this.teacherError = null;
      }
    },
    async ratingFilter(obj) {
      this.rating = obj;
      await this.setRating(this.rating);
      await this.getCoreSkillDevelopments();
    },
    searchQuery: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getCoreSkillDevelopments();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  methods: {
    ...mapActions("coreSkill", [
      "getCenterAdminCoreSkillDevelopment",
      "fetchCenterAdminCoreSkills",
      "logProfessionalDevelopment",
      "logProfessionalDevelopmentForTeachers",
      "setRating"
    ]),


    ...mapActions("center", [
      "getLearningCenterRoomsWithTeacher"
    ]),
    ...mapActions("room", [
      "getTeachersByRoomId",
    ]),

    toggleAllTeachers(event) {
      if (event.target.checked) {
        this.selectedTeachers =  this.teachersIds;
      }else{
        this.selectedTeachers = [];
      }
      /*this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
      this.getLearningStories(this.startDate, this.endDate);*/
    },
    async onChangeRating(val, key) {
      this.rating = val;
      this.activeRatingKey = key;
      await this.setRating(this.rating);
      this.getCoreSkillDevelopments();
    },
    async getCoreSkillDevelopments() {
      let filter = {
        coreSkill: this.coreSkillId,
        rating: this.rating,
      };
      let info = {
        data: filter,
        search: this.searchQuery
      };
      this.$vs.loading()
      await this.getCenterAdminCoreSkillDevelopment(info)
        .then((res) => {
          this.$vs.loading.close();
          this.coreSkillDevelopments = res.data.data.map((item => {
            return item.resources
          }));
        })
        .catch(err => {
          this.$vs.loading.close()
        })
    },
    async selectCoreSkill(val, key) {
      this.coreSkillId = await val._id;
      this.rating = 1;
      this.activeRatingKey = 0;
      this.activeCoreSkillKey = await key;
      await this.getCoreSkillDevelopments();
    },
    documentDetailPage(fastCheckId,rating){

      this.resourceId = fastCheckId;
      this.rating = rating;

      this.teacherPopup = true;
      //let routeData = this.$router.resolve({ name: 'center-admin-log-professional-development', params: { fastCheckId: fastCheckId, rating: rating } });
      //window.open(routeData.href, '_blank');
    },
    documentSendPopup(){
      this.teacherPopup = true;
    },
    cancelSendTeacher(){
      this.teacherPopup = false;
    },
    async getAllRoomOfCenter() {
      await this.getLearningCenterRoomsWithTeacher(this.centerId).then(
        (res) => {
          this.rooms = res.data.data;
        }
      );

      //getRoomListCenterAdmin
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    sendToTeacher(item) {
      this.roomError = false;
      this.teacherError = false;
      let resourceId;
      if (this.isOnlyTeacher) {
        this.selectedTeachers = [this.ownId]
        resourceId = item
      } else {
        resourceId = this.resourceId
      }
      if(!this.currentRoom){
        this.roomError = true;
        return false
      }

      if(this.selectedTeachers.length <= 0){
        this.teacherError = true;
        return false
      }

      const log = {
        coreSkillId: this.coreSkillId,
        resourceId,
        teacherIds: this.selectedTeachers,
        rating: this.rating,
      }

      let self = this;

      this.$vs.loading()
      this.logProfessionalDevelopmentForTeachers(log)
        .then((res) => {
          self.showMessage("Success", "Self assessment successfully.", "success");
          self.teacherPopup = false;
          self.$vs.loading.close()
        })
        .catch(err => {
          self.$vs.loading.close()
        })
      console.log("send");
    }
  },
  created() {
    this.teacherRole = this.$store.state.AppActiveUser.userType == 'teacher' ? true: false
    this.isOnlyTeacher = this.$store.state.AppActiveUser.role == 'teacher' ? true: false
    this.roomName = this.$store.state.AppActiveUser.room.name
  },
  mounted() {
    this.centerId = this.$store.state.AppActiveUser.learningCenter._id;
    this.selectedCoreSkill = this.data;
    //this.coreSkillDevelopments = this.selectedCoreSkill.professionalDevelopmentResources ? this.selectedCoreSkill.professionalDevelopmentResources[0].resources : '';
    this.getCoreSkillDevelopments();

    if (this.teacherRole) {
      this.isLeadTeacher = true;
      this.currentRoom = this.$store.state.AppActiveUser.room._id;
      this.rooms.push({
        name: this.$store.state.AppActiveUser.room.name,
        _id: this.$store.state.AppActiveUser.room._id
      })
        this.getTeachersByRoomId(this.$store.state.AppActiveUser.room._id)
          .then(async res => {
            this.teachers = res.data.data;
            this.teachersIds = res.data.data.map(item => {
              return item._id;
            });
          })
          .catch(err => {
            console.log(err);
          });  
    } else {
      this.getAllRoomOfCenter();
    }

    this.ownId = this.$store.state.AppActiveUser._id,
    this.teacherFullName = this.$store.state.AppActiveUser.fullName
  }
}
</script>
